import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";

import * as styles from "./AppIllustration.module.scss";

const AppIllustration = () => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(styles.appIllustration, "app-illustration")}
      role="img"
      aria-labelledby="app-illustration-label"
      aria-describedby="app-illustration-description"
    >
      <span id="app-illustration-label" className="sr-only">
        {t("landing.app_reimbursement.illustration.title")}
      </span>
      <p id="app-illustration-description" className="sr-only">
        {t("landing.app_reimbursement.illustration.description")}
      </p>
      <div
        className={cn(
          styles.appIllustrationScreen,
          styles.appIllustrationScreenHome,
          "app-illustration-screen-home"
        )}
      >
        <ul>
          <li>{t("landing.app_reimbursement.illustration.talk_to_vet")}</li>
          <li>{t("landing.app_reimbursement.illustration.reimbursement")}</li>
          <li>{t("landing.app_reimbursement.illustration.vet_card")}</li>
        </ul>
      </div>
      <div
        className={cn(
          styles.appIllustrationScreen,
          styles.appIllustrationScreenReimbursement,
          "app-illustration-screen-reimbursement"
        )}
      >
        <ol>
          <li>{t("landing.app_reimbursement.illustration.claim_received")}</li>
          <li>{t("landing.app_reimbursement.illustration.under_review")}</li>
          <li>
            <svg className={cn(styles.icon, "icon", "icon--completed")} aria-hidden="true">
              <use href="#icon-check" />
            </svg>
            {t("landing.app_reimbursement.illustration.amount")}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default AppIllustration;
