import cn from "classnames";
import { graphql } from "gatsby";
import parse from "html-react-parser";

import AppIllustration from "../../atoms/app-illustrations/AppIllustration";
import SectionTitle from "../../molecules/section-title/SectionTitle";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";

const AppReimbursement = ({ data: { title, subtitle }, adoptionClassName }) => {
  const componentName = "or-app-reimbursement";

  const { ref, isVisible } = useVisibilityTracker("app-reimbursement", {
    threshold: 0.5,
  });

  return (
    <section
      ref={ref}
      className={cn(componentName, adoptionClassName, isVisible && `${componentName}--visible`)}
    >
      <SectionTitle title={parse(title)} subtitle={subtitle} adoptionClassName={"section-title"} />
      <AppIllustration />
    </section>
  );
};

export const query = graphql`
  fragment AppReimbursementFragment on ContentfulAppReimbursementSection {
    title
    subtitle
  }
`;

export default AppReimbursement;
